import Vue from 'vue';
import App from './App.vue';

import './plugins';
import './index.scss';

import './core/index';

import router from './router/router';

import {createPinia, PiniaVuePlugin} from 'pinia';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.productionTip = false;

new Vue({
  router,
  pinia,
  render: h => h(App),
}).$mount('#app');
