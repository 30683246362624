<template>
  <div class="no-data-exists d-flex flex-column justify-content-center align-items-center" v-if="show">
    <p class="text-muted m-0">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'NotWorking',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'No Data Available'
    },
  },
}
</script>

<style scoped>

.no-data-exists {
  display: flex;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

</style>