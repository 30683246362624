import {defineStore} from 'pinia';
import httpService from '@/core/services/httpService';

export const usePatientStore = defineStore('patient', {
  state: () => ({}),
  actions: {
    async createPrescription(data) {
      return await httpService.post(`api/v1/prescriptions/`, data);
    },
  },
})