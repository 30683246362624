import moment from 'moment';

export class Formatter {
  static toDateTime(value, format) {
    if (format) {
      return moment(value).format(format);
    } else {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    }
  }
}
