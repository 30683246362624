import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget
      Vue.prototype.$ws.initGlobalListener();
      console.log('SOCKET_ONOPEN');
    },
    SOCKET_ONCLOSE(state, event) {
      console.log('SOCKET_ONCLOSE');
    },
    SOCKET_ONERROR(state, event) {
      console.error('SOCKET_ONERROR', state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      console.log('SOCKET_ONMESSAGE');
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.log('SOCKET_RECONNECT');
    },
    SOCKET_RECONNECT_ERROR(state) {
      console.log('SOCKET_RECONNECT_ERROR');
    },
  },
});

export {
  store,
  Vuex,
}
