import Vue from 'vue';
import VueNativeSock from 'vue-native-websocket'
import {store} from './store';

Vue.use(VueNativeSock, 'ws://localhost:9090', {
  store: store,
  connectManually: true,
  format: 'json',
  reconnection: true,
  reconnectionDelay: 300,
});
