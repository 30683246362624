import Vue from 'vue';

class SocketService {
  currentListeners = {};

  async connect() {
    await Vue.prototype.$connect(process.env.VUE_APP_WS_URL);
  }

  initGlobalListener() {
    Vue.prototype.$socket.onmessage = (response) => {
      let data = JSON.parse(response.data);

      for (let name in this.currentListeners) {
        if (name === data.action) {
          if (typeof data.data === 'string') {
            this.currentListeners[name](JSON.parse(data.data));
          } else {
            this.currentListeners[name](data.data);
          }
        }
      }
    };
  }

  sendObj(data, action) {
    Vue.prototype.$socket.sendObj({data, action})
  }

  listen(name, handler) {
    this.currentListeners[name] = handler;
  }

  remove(name) {
    if (this.currentListeners[name]) {
      delete this.currentListeners[name];
    }
  }
}

Vue.prototype.$ws = new SocketService();
