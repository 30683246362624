import {defineStore} from 'pinia';
import httpService from '@/core/services/httpService';

export const useOperatorStore = defineStore('operator', {
  state: () => ({
    prescriptionCards: [],
    prescriptionsHistory: [],
    loading: false,
    historyLoading: false,
  }),
  actions: {
    async getPrescriptions() {
      const self = this;
      self.loading = true;
      const {success, body} = await httpService.get('api/v1/prescriptions/');
      self.loading = false;
      if (success) {
        self.prescriptionCards = body;
      }
    },
    async updatePrescription({uuid, data}) {
      const self = this;
      const response = await httpService.put(`api/v1/prescriptions/${uuid}/`, data);
      if (response.success) {
        if (data.current_status === 3) {
          self.prescriptionCards = self.prescriptionCards.filter(pc => pc.uuid !== uuid);
        } else {
          const index = self.prescriptionCards.findIndex(pc => pc.uuid === uuid);
          if (index !== -1) {
            self.prescriptionCards[index] = response.body;
          }
        }
        self.prescriptionCards = [...self.prescriptionCards];
      }
      return response;
    },
    updatePrescriptionCards(data) {
      this.prescriptionCards.push(data);

      this.prescriptionCards = [...this.prescriptionCards];
    },
    async getPrescriptionsHistory(params = null) {
      const self = this;
      self.historyLoading = true;
      const {success, body} = await httpService.get('api/v1/prescriptions/history/', {params});
      self.historyLoading = false;
      if (success) {
        self.prescriptionsHistory = body;
      }
    },
    async updatePrescriptionHistory({uuid, data}) {
      const self = this;
      const response = await httpService.put(`api/v1/prescriptions/history/${uuid}/`, data);
      if (response.success) {
        self.prescriptionsHistory = self.prescriptionsHistory.filter(pc => pc.uuid !== uuid);
      }
      return response;
    },
    updateHistoryCards(data) {
      this.prescriptionsHistory.push(data);

      this.prescriptionsHistory = [...this.prescriptionsHistory];
    },
  },
})