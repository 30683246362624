<template>
  <div class="mb-5 mt-3">
    <div class="p-3 d-flex justify-content-center align-items-center">
      <b-button variant="outline-secondary back-button" size="lg" @click="onBackClick">
        <i class="fas fa-chevron-left"/>
        Back
      </b-button>
      <h3 class="mb-0 history-title">History of Today's Collections</h3>
    </div>
    <div class="mt-3 mb-4">
      <hr/>
      <div class="row justify-content-center align-items-center">
        <b-form-input
            v-model="filters.id_number" size="lg" class="col-3 mr-3" placeholder="ID Number"
            @keydown.enter="onFilterClick">
        </b-form-input>
        <b-form-input
            v-model="filters.barcode" size="lg" class="col-3 mr-3" placeholder="Barcode"
            @keydown.enter="onFilterClick">
        </b-form-input>
        <div>
          <b-button variant="outline-warning" size="lg" class="float-right" @click="onClearClick">
            <i class="fas fa-times"/>
          </b-button>
          <b-button variant="outline-info" size="lg" class="float-right mr-2" @click="onFilterClick">
            <i class="fas fa-search"/>
          </b-button>
        </div>
      </div>
      <hr/>
    </div>
    <loader :show="historyLoading" fullscreen/>
    <div v-if="!historyLoading" class="row mt-3 ml-2 mr-2 justify-content-center">
      <not-working :show="!prescriptionsHistory.length"/>
      <collection-card class="m-2 collection-card" v-for="card in prescriptionsHistory" :key="card.uuid" :object="card"
                       @onCardUpdate="onCardUpdate"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'pinia';
import {useOperatorStore} from '@/store/operator';
import CollectionCard from '@/modules/Operator/CollectionCard';
import Loader from '@/core/components/loader/loader';
import NotWorking from '@/core/components/not-working/NotWorking';

export default {
  name: 'CollectionHistory',
  components: {NotWorking, Loader, CollectionCard},
  data() {
    return {
      filters: {
        id_number: '',
        barcode: '',
      }
    }
  },
  computed: {
    ...mapState(useOperatorStore, ['prescriptionsHistory', 'historyLoading']),
  },
  methods: {
    ...mapActions(useOperatorStore, ['getPrescriptionsHistory', 'updatePrescriptionHistory',
      'updateHistoryCards']),
    async onCardUpdate({uuid, data}) {
      const confirmed = await this.$confirm('Are you sure you want to return the card?', 'Please Confirm');
      if (confirmed) {
        const {success} = await this.updatePrescriptionHistory({uuid, data});
        if (success) {
          this.$toast('success', 'Card Returned Successfully');
        } else {
          this.$toast('danger', 'Unable to return card');
        }
      }
    },
    onBackClick() {
      this.$router.push({name: 'operators'});
    },
    onClearClick() {
      this.filters = {
        id_number: '',
        barcode: '',
      }
      this.getPrescriptionsHistory(this.filters);
    },
    onFilterClick() {
      this.getPrescriptionsHistory(this.filters);
    },
  },
  mounted() {
    this.getPrescriptionsHistory();

    this.$ws.connect();
    this.$ws.listen('SEND_CARD_TO_HISTORY', this.updateHistoryCards);
  },
  destroyed() {
    this.$ws.remove('SEND_CARD_TO_HISTORY');
  }
}
</script>

<style lang="scss" scoped>
.collection-card {
  min-width: 430px;
}

.back-button {
  margin-right: auto;
}

.history-title {
  margin-right: auto;
  transform: translateX(-48px);
}
</style>