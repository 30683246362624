import Vue from 'vue';
import moment from 'moment';
import {Formatter} from '@/core/services/formatterService';

Vue.filter('toDateTime', function (value, format) {
  if (!value) return '';
  return Formatter.toDateTime(value, format);
});

Vue.filter('relativeDate', function (value) {
  if (!value) return '';
  return moment(value).fromNow();
});

Vue.filter('toUpperCaseFirstSentence', function (value) {
  if (!value) return '';
  return value.split(/ /g).map(word => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`).join(' ');
});