import {defineStore} from 'pinia';
import httpService from '@/core/services/httpService';

export const useSettingStore = defineStore('setting', {
  state: () => ({
    settings: [],
    settingsList: {},
    loading: false,
  }),
  actions: {
    async getSettings() {
      const self = this;
      const {success, body} = await httpService.get('api/v1/settings/');
      if (success) {
        self.settings = body;
      }
    },
    async getSettingsList() {
      const self = this;
      self.loading = true;
      const {success, body} = await httpService.get('api/v1/settings/settings_list/');
      self.loading = false;
      if (success) {
        self.settingsList = body;
      }
    },
    async updateSetting({uuid, data}) {
      const self = this;
      const {success, body} = await httpService.put(`api/v1/settings/${uuid}/`, data);
      if (success) {
        self.settingsList[data.key] = data.value;

        const index = self.settings.findIndex(s => s.uuid === uuid);
        if (index !== -1) {
          self.settings[index] = body;
        }
        self.settingsList = {...self.settingsList};
        self.settings = [...self.settings];
      }
    },
  },
})