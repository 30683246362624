<template>
  <b-card class="collection-card" :style="{borderColor: computedStatusData.color}">
    <h4 class="text-center mt-2 mb-3" :style="{color: computedStatusData.color}">
      {{ computedStatusData.header }}
    </h4>
    <table class="p-3 text-nowrap" style="width: 100%">
      <tr>
        <th><h4>ID Number:</h4></th>
        <td class="text-right"><h4>{{ object.id_number }}</h4></td>
      </tr>
      <tr>
        <th><h4>Arrived Time:</h4></th>
        <td class="text-right"><h4>{{ object.arrived_date | toDateTime }}</h4></td>
      </tr>
      <tr>
        <th><h4>Card Arrived:</h4></th>
        <td class="text-right"><h4>{{ cardArrivedTime | toUpperCaseFirstSentence }}</h4></td>
      </tr>
    </table>
    <div class="text-center mt-4">
      <img :id="`${object.uuid}-barcode`" alt="" width="120" height="120" src=""/>
      <p class="display-4 text-muted mb-0 pt-2">{{ object.barcode }}</p>
      <b-button :variant="computedStatusData.variant" class="mt-3 mb-2 text-nowrap" style="min-width: 50%"
                @click="onStatusButtonClick">
        {{ computedStatusData.text }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'CollectionCard',
  props: {
    object: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      cardArrivedTime: null,
    }
  },
  computed: {
    computedStatusData() {
      if ([0, '0'].includes(this.object.current_status)) {
        return {
          header: 'Arrived',
          variant: 'outline-secondary',
          text: `Move to "In Progress"`,
          color: '#6c757d',
          nextStatusId: 1,
          nextStatusDateFieldName: 'in_progress_on',
        };
      } else if ([1, '1'].includes(this.object.current_status)) {
        return {
          header: 'In Progress',
          variant: 'outline-warning',
          text: `Move to "Ready to Handout"`,
          color: '#ffc107',
          nextStatusId: 2,
          nextStatusDateFieldName: 'ready_to_handout_on',
        };
      } else if ([2, '2'].includes(this.object.current_status)) {
        return {
          header: 'Ready to Handout',
          variant: 'outline-success',
          text: `Move to "Completed"`,
          color: '#28a745',
          nextStatusId: 3,
          nextStatusDateFieldName: 'completed_on',
        };
      } else {
        return {
          header: 'Completed',
          variant: 'outline-danger',
          text: 'Return to "Ready to Handout"',
          color: '#dc3545',
          nextStatusId: 2,
          nextStatusDateFieldName: 'returned_on',
        };
      }
    },
  },
  methods: {
    generateBarCode() {
      const url = `https://api.qrserver.com/v1/create-qr-code/?data=${this.object.barcode}`;
      const barcode = document.getElementById(`${this.object.uuid}-barcode`);
      if (barcode) {
        barcode.src = url;
      }
    },
    onStatusButtonClick() {
      let params = {
        ...this.object,
        current_status: this.computedStatusData.nextStatusId,
        [this.computedStatusData.nextStatusDateFieldName]: new Date().toISOString(),
      }

      if ([3, '3'].includes(this.object.current_status)) {
        params.completed_on = null;
      }

      this.$emit('onCardUpdate', {
        uuid: this.object.uuid,
        data: {...params},
      });
    },
  },
  mounted() {
    this.generateBarCode();

    this.cardArrivedTime = this.$options.filters.relativeDate(this.object.arrived_date);
    window.setInterval(() => {
      this.cardArrivedTime = this.$options.filters.relativeDate(this.object.arrived_date);
    }, 5000);
  },
}
</script>

<style lang="scss" scoped>
.collection-card {
  cursor: pointer;
}
</style>